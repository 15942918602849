export class Hydrant {
  constructor() {
    this.cart();
  }

  cart(data) {
    hydrateCart(data);
  };
}

export const hydrateCart = (cartData) => {
  if (!cartData) {
    cartData = JSON.parse(localStorage.getItem('cart')) || null;
  }

  if (cartData) {
    document.querySelectorAll('.cart_items_number').forEach(el => {
      el.innerHTML = parseFloat(cartData.items_number).toFixed();
    });

    document.querySelectorAll('.cart_full_price').forEach(el => {
      el.innerHTML = cartData.total_price_with_currency;
    });
  }
}

export default Hydrant;
